#nosotros.background {
    background: linear-gradient(to left, #85C1E9, #AED6F1) !important;
}

.text-content {
    width: 100%; /* Ajusta el ancho del contenedor de texto */
    flex-grow: 1; /* Para que el contenido de texto ocupe el espacio restante */
}

.card-custom-image {
    width: 0;
    height: 0;
    max-width: 50% !important;
    max-height: 100% !important; /* Permite que la altura se ajuste automáticamente según el ancho */
    object-fit: cover !important;
    object-position: center !important;
    /* FIXED */
    filter: grayscale(50%) brightness(80%) contrast(100%); /* Aplica el filtro */
    clip-path: circle(50% at 50% 50%);
    margin: 0;
}

@media screen and (min-width: 768px) {
    .card-custom-image {
        width: 250px;
        height: 250px;
        margin-left: 10rem;
    }
}

@media screen and (min-width: 1024px) {
    .card-custom-image {
        width: 350px;
        height: 350px;
    }
}

@media screen and (min-width: 1440px) {
    .card-custom-image {
        width: 550px;
        height: 550px;
    }
}