.background {
    background: var(--color-gray-100) !important;
    width: 100%;
    max-width: 100%;
    padding: var(--padding-container-vertical-mobile) var(--padding-container-horizontal-mobile) !important;
}

@media screen and (min-width: 426px) {
    .background {
        padding: var(--padding-container-vertical-tablet) var(--padding-container-horizontal-tablet) !important;
    }
}

@media screen and (min-width: 769px) {
    .background {
        padding: var(--padding-container-vertical-laptop) var(--padding-container-horizontal-laptop) !important;
    }
}

@media screen and (min-width: 1025px) {
    .background {
        padding: var(--padding-container-vertical-desktop) var(--padding-container-horizontal-desktop) !important;
    }
}

.background-container {
    width: 100%;
    height: 100%;
}

.contact {
    text-align: center;
    padding: 1rem 0;
    font-weight: bold;
    text-transform: uppercase;
}

.divider {
    margin-top: 1rem;
    height: 1px;
    background-color: #757c90;
    margin-left: 1rem;
    margin-right: 1rem;
}


.footer-link div p {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.4; /* Ajusta este valor según sea necesario */
}

.footer-link div img {
    margin-right: 8px; /* Ajusta el margen derecho según sea necesario */
}

.footer-link:link, .footer-link:visited, .footer-link:active {
    color: var(--color-white);
    text-decoration: none;
}

.btn-close:focus {
    box-shadow: none !important;
}

.background-img {
    width: 100%;
    height: 100%;
    object-position: center;
}

.btn-custom {
    background-color: var(--color-blue-500) !important;
    color: var(--color-white) !important;
    border: 0 !important;
    width: fit-content !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border-radius: 0 !important;
    margin-top: auto;
    margin-bottom: 1.5rem !important;
    text-transform: uppercase !important;
    transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.btn-custom:hover {
    background-color: #0f5596 !important;
    transform: translateY(-2px) !important;
}

.card-customer {
    background-color: transparent !important;
    border: none !important;
}

.card-customer-image {
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.card-customer-image:hover {
    filter: grayscale(0%);
}

.card {
    height: 100% !important;
    border-radius: 0 !important;
    color: black !important;
}

.back {
    width: 100%;
    height: var(--max-height-mobile); /* height: 100vh; */
    background-color: var(--color-white); /* E5E5E5 */
    object-position: bottom;
    object-fit: cover;
}

.back.home {
    object-position: center;
}

.footer {
    width: 100%;
    background-color: #0D1117;
    color: var(--color-white);
}

.footer-text.rights {
    color: var(--color-white);
    text-align: center;
    padding: .5rem 0;
    font-size: .8em;
}

.footer-container {
    color: var(--color-white);
    padding: .5rem 1.5rem;
}

.footer-item {
    padding: .5rem 0;
}

.footer-item.social-networks {
    display: flex;
    justify-content: center;
}

.columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.column {
    height: 100%;
    width: 50%;
}

.column-img {
    object-position: right;
    object-fit: cover;
}

.text-container {
    padding: 1rem 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-container.home {
    height: 100%;
    width: 100%;
    text-align: end;
    justify-content: end;
}

.text-container.about-us {
    height: 100%;
    width: 100%;
}

.title-about-us {
    color: #2F487E !important;
}

.subtitle-about-us {
    color: #2F487E !important;
}


.text-container.customers {
    width: 100%;
    align-items: center;
}

.text-container.customers .text {
    color: var(--color-blue-500) !important;
}

.text {
    color: black;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.title {
    font-size: 2em;
    font-weight: bold;
    line-height: 1.6;
}

.subtitle {
    font-size: 1.3em;
    line-height: 1.6;
}

.card-custom {
    background: rgba(255, 255, 255, .1);
    color: var(--color-white);
    padding: 5rem 5rem;
    margin: 8rem 20rem;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, .2);
}

.card-custom-content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 100%;
}

.modal-content {
    background: #F0F0F0;
    border-radius: 0 !important;
}


.background-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Estilos para los íconos de flecha */
.icon-left,
.icon-right {
    margin: 15px; /* Ajusta el relleno según sea necesario */
    color: var(--color-blue-500); /* Cambia el color de fondo */
}

.owl-prev,
.owl-next {
    border-radius: 50% !important; /* Hace que los íconos tengan forma circular */
    border: 1px solid var(--color-blue-500) !important; /* Añade un borde al ícono */
    transition: background 0.3s ease; /* Añade una transición al color de fondo y al borde */
}

/* Estilos para cambiar el tamaño al pasar el mouse */
.owl-prev:hover,
.owl-next:hover {
    background: rgba(0, 0, 0, .1) !important; /* Cambia el color de fondo al pasar el mouse */
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-item {
    display: flex;
    margin-bottom: 15px;
}

.btn-close {
    color: #000000 !important;
}