/* Navbar */

nav {
    background: rgba(255, 255, 255, 1) !important;
    padding: .5rem !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-brand {
    height: 50px !important;
    width: auto !important;
}

.navbar-toggler {
    color: var(--color-white) !important;
    border: 0 !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.nav-link {
    font-weight: bold !important;
    font-size: 1em !important;
    color: var(--color-white) !important;
}

.nav-link:hover {
    cursor: pointer !important;
}

.navbar-custom-item {
    color: var(--color-blue-500) !important;
}

.navbar-custom-item:hover {
    background: var(--color-blue-500) !important;
    color: #FFFFFF !important;
}

.navbar-btn-menu:hover {
    background: #FFFFFF !important;
}
