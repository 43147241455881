.services, .customers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
}

.carousel-container {
    width: 100%;
    max-width: 100%;
}

.item:hover {
    cursor: pointer !important;
}

.card-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: .5rem 1rem;
}

.card-service img {
    width: 150px !important;
    height: 150px !important;
    padding: 2rem !important;
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.service-title {
    padding: .5rem 0 !important;
    text-align: center !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-size: 1.1em !important;
}

.service-description {
    padding: .5rem 0 !important;
    text-align: center !important;
    color: #000000 !important;
    font-size: .9em !important;
}

.text-container.services {
    width: 100% !important;
    align-items: center !important;
}

.text-container.services .text {
    color: var(--color-blue-500) !important;
}

.button-expanded {
    color: var(--color-blue-500) !important;
    height: 20px !important;
    margin: .5rem 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-services {
    width: 100%;
}

.sub-service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-blue-500);
}

.sub-service-content {
    margin-left: 1rem;
}

.sub-service-description, .sub-service-title {
    padding: .5rem 0;
}

/* .owl-theme {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
} */

.item-service {
    height: 100%;
}

.owl-stage {
    display: flex;
    align-items: stretch;
}