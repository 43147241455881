#partners.background {
    background: linear-gradient(to left, #BBD7EF, #D6EAF8) !important;
}

.card-grid-partner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.card-partner {
    width: 150px;
    height: 150px; /* Alto de la tarjeta */
    background-color: var(--color-gray-100);
    border: none;
    border-radius: 25px;
}

.card-partner-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    padding: 1.5rem;
}
