/* Home */

#inicio.background {
    background: var(--color-black-opaque-50) !important;
    height: var(--max-height-background-mobile);
    margin-top: var(--navbar-height-mobile);
}

@media screen and (min-width: 768px) {
    /* Selecciona todos los div y ajusta el tamaño de fuente a 15px */
    #inicio.background {
        height: var(--max-height-background);
        margin-top: var(--navbar-height-mobile);
    }
}