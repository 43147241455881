/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* General */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */

:root {
    --color-white: #FFFFFF;
    /* --color-blue-500: #146DC2; */
    --color-blue-500: #1192D1;
    --color-gray-100: #F5F5F5;
    --color-black-opaque-50: rgba(0, 0, 0, 0.5);
    --max-height-mobile: 50vh;
    --navbar-height-mobile: 96px;
    --max-height-background-mobile: calc(var(--max-height-mobile) - var(--navbar-height-mobile));
    --max-height-background: calc(100vh - var(--navbar-height-mobile));
    --navbar-brand-height-mobile: 40px;
    --padding-container-vertical-mobile: 1rem;
    --padding-container-horizontal-mobile: 1rem;
    --padding-container-vertical-tablet: 1.5rem;
    --padding-container-horizontal-tablet: 5rem;
    --padding-container-vertical-laptop: 2rem;
    --padding-container-horizontal-laptop: 8rem;
    --padding-container-vertical-desktop: 2.5rem;
    --padding-container-horizontal-desktop: 15rem;
}

/* Content */

body {
    font-family: 'Roboto', sans-serif !important;
    background-image: url('./images/background-body-6.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif !important;
}

a {
    text-decoration: none !important;
}

p {
    margin-bottom: 0 !important;
}

li {
    list-style: none !important;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

::-webkit-scrollbar-thumb {
    background: var(--color-blue-500);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-blue-500);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:active {
    background-color: var(--color-blue-500);
}

::-webkit-scrollbar-track {
    background: #CCCCCC;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
    background: #CCCCCC;
}
